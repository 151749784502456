import Request from '../request';

export default {
  async getList(pageObj, searchObj) {
    try {
      return await Request.get("/order/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  }
}