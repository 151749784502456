import Request from '../request';
import store from '../../store';
import router from '../../router';
import { Config } from '@/config';

export default {
  async submit(data) {
    try {
      return await Request.post("/login", data);
    } catch(err) {
      return null;
    }
  },

  async check() {
    try {
      return await Request.post("/check/token");
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async logout() {
    try {
      const res = await Request.get("/log/out");
      if (res) {
        store.commit("updateUserInfo", {type: ''});
        localStorage.setItem(Config.TokenKey, '');
        console.log(router,'dengchu')
        router.push({name: 'LoginView'});
      }
    } catch (err) {

    }
  }
}