// console.log('Current Process Env NODE_ENV: ', process.env.NODE_ENV);
const config = {
  //当前环境
  Env: process.env.NODE_ENV === 'production' ? 'Pro' : 'Dev',   //Dev开发环境，Pro则为发布环境
  // localstorage 保存token的 key 
  TokenKey: 'AuthToken',
  MinappAxiosBaseURL: 'https://admin.chulinrz.com',
  MinappAxiosBaseApi: '/minapp/api',
  // 开发环境
  MinappAxiosBaseURLDev: 'http://localhost:8080',
  MinappAxiosBaseApiDev: '/minappdev/api',

  MinappAxiosBasePreAdmin: '/admin',
  MinappAxiosBasePrePublic: '/public',

  AxiosTimeout: 15000,

  // UserTypeKey: 'UserType',
  AdminMenuType: 'Admin',
  ProductorMenuType: 'Productor',
  UIerMenuType: 'UIer',
  CurtainSystemType: 'CurtainSystem',
  OrderToImageType: 'OrderToImage',

  //PageSizeKey
  PageSizeKey: 'PageSize',
  PageSizeNumber: 15,
  PageSizeArray: [10, 15, 20, 30, 50, 100],

  HomeRouteName: 'HomeView',
  RefreshRouteName: 'RefreshView'
}
// console.log('Config: ', config);
export default config;