import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "element-plus/theme-chalk/el-message.css";
import "vue3-json-viewer/dist/index.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import JsonViewer from 'vue3-json-viewer';

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(router).use(JsonViewer).mount('#app');
