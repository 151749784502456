import Request from '../request';
import { getRightRequestUrl } from '@/assets/util/util';

export default {
  async refresh(type) {
    try {
      return await Request.get(getRightRequestUrl('public') + "/sync?t=" + type);
    } catch(err) {
      return null;
    }
  }
}