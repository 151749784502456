import { Config } from '../../config';
import store from '../../store';
import router from '../../router';

const getShowMenus = (type) => {
  const root = router.getRoutes().find(item => item.path === '/');
  const menuList = root.children || [];
  const showMenus = [];
  menuList.forEach(menu => {
    if (menu.meta.showType && menu.meta.showType.indexOf(type) >= 0 && !menu.meta.hidden) {
      const item = {
        name: menu.name,
        label: menu.meta.title,
        path: `/${menu.path}`,
        icon: menu.meta.icon
      };
      showMenus.push(item);
      if (menu.children) {
        const children = [];
        menu.children.forEach(child => {
          if (child.meta.showType && child.meta.showType.indexOf(type) >= 0 && !child.meta.hidden) {
            children.push({
              name: child.name,
              label: child.meta.title,
              path: `${item.path}/${child.path}`,
              icon: child.meta.icon
            })
          }
        });
        item.children = children;
      }
    }
  })
  return showMenus;
}

export default {
  getMenuListToShow() {
    // const userType = localStorage.getItem(Config.UserTypeKey) || 'Admin';
    // const store = useStore();
    const userType = store.state.userInfo.type;
    const showMenus = getShowMenus(userType);
    return showMenus;
  }
}