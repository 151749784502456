import { Config } from '@/config';
import { createStore } from 'vuex'

export default createStore({
  state: {
    isCollapse: true,
    userInfo: {type: ''},
    actionTitle: '',
    pageTabs: {
      activeName: Config.HomeRouteName,
      list: [{
        name: Config.HomeRouteName,
        title: '首页',
        query: {}
      }]
    }
  },
  getters: {
  },
  mutations: {
    updateIsCollapse(state, payload) {
      state.isCollapse = !state.isCollapse;
    },
    updateUserInfo(state, payload) {
      state.userInfo = payload;
    },
    addPageTab(state, payload) {
      if (!payload.onlyUpdateActiveName) {
        let i = state.pageTabs.list.findIndex(item => item.name === payload.name);
        if (i === -1) {
          if (payload.index > 0) {
            state.pageTabs.list.splice(payload.index, 0, {
              name: payload.name,
              title: payload.title,
              query: payload.query
            })
          } else {
            state.pageTabs.list.push({
              name: payload.name,
              title: payload.title,
              query: payload.query
            });
          }
        } else {
          // 找到就要更新query
          state.pageTabs.list[i].query = payload.query;
        }
      }
      // 激活该tab
      state.pageTabs.activeName = payload.name;
    },
    delPageTab(state, payload) {
      if (payload.name === Config.HomeRouteName) return;
      let activeName = state.pageTabs.activeName;
      if (payload.name === activeName && payload.nextActiveName) {
        // nextActiveName: 删除当前Active标签，并定位到下一个标签
        state.pageTabs.list.forEach((item, index) => {
          if (item.name === payload.name) {
            const nextTab = state.pageTabs.list[index + 1] || state.pageTabs.list[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        })
      }
      state.pageTabs.activeName = activeName;
      state.pageTabs.list = state.pageTabs.list.filter(item => item.name !== payload.name);
    },
    delPageTabs(state, payloads = []) {
      state.pageTabs.list = state.pageTabs.list.filter(item => {
        return item.name === Config.HomeRouteName || payloads.indexOf(item.name) === -1;
      });
      let activeName = state.pageTabs.activeName;
      const activeNameExist = state.pageTabs.list.findIndex(item => item.name === activeName);
      if (activeNameExist >= 0) {
        // activeName还存在
      } else {
        // activeName不存在了
        const len = state.pageTabs.list.length;
        activeName = state.pageTabs.list[len - 1].name;
      }
      state.pageTabs.activeName = activeName;
    }
  },
  actions: {
  },
  modules: {
  }
})
