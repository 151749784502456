import Request from '../request';

export default {
  async getArticleList(page, limit, searchObj) {
    try {
      return await Request.get("/article/list", {params: {page, limit, ...searchObj}});
    } catch(err) {
      return null;
    }
  },
  async saveArticle(data) {
    try {
      return await Request.post("/article/save", data);
    } catch(err) {
      return null;
    }
  },
  async modifyArticleStatus(statusObj) {
    try {
      return await Request.put("/article/status", statusObj);
    } catch(err) {
      return null;
    }
  },
  async deleteById(id) {
    try {
      return await Request.delete(`/article/delete/${id}`);
    } catch(err) {
      return null;
    }
  },
  
  async getPageConfigList(page, limit, searchObj) {
    try {
      return await Request.get('/page/list', {params: {page, limit, ...searchObj}});
    } catch(err) {
      return null;
    }
  },
  async getPageConfigDetail(id) {
    try {
      return await Request.get('/page/detail/' + id);
    } catch(err) {
      return null;
    }
  },
  async savePageJson(object) {
    try {
      return await Request.post('/page/save', object);
    } catch (err) {
      return null;
    }
  },
  async modifyPageConfigStatus(statusObj) {
    try {
      return await Request.put("/page/status", statusObj);
    } catch(err) {
      return null;
    }
  },
  async deletePageConfigById(id) {
    try {
      return await Request.delete(`/page/delete/${id}`);
    } catch(err) {
      return null;
    }
  }
}