import { Config } from '@/config';
import Request from '../request';

export default {
  async getUserInfo() {
    try {
      return await Request.get("/userinfo");
    } catch(err) {
      return null;
    }
  },
  async getUserList(page, limit, searchObj) {
    try {
      return await Request.get("/user/index", {params: {page, limit, ...searchObj}});
    } catch(err) {
      return null;
    }
  },
  async saveUser(data) {
    try {
      return await Request.post("/user/save", data);
    } catch(err) {
      return null;
    }
  },
  async modifyStatus(statusObj) {
    try {
      return await Request.put("/user/modify/status", statusObj);
    } catch(err) {
      return null;
    }
  },
  async deleteById(id) {
    try {
      return await Request.delete(`/user/delete/${id}`);
    } catch(err) {
      return null;
    }
  },
  async modifyPwd(pwd) {
    try {
      return await Request.put('/user/modify/password', pwd);
    } catch(err) {
      return null;
    }
  }
}