import Request from '../request';

export default {
  async getFurnitureList(pageObj, searchObj) {
    try {
      return await Request.get("/saled/furniture/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
  async getLampList(pageObj, searchObj) {
    try {
      return await Request.get("/saled/lamp/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
}