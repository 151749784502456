import Request from '../request';

export default {
  async getCompanyList(pageObj, searchObj) {
    try {
      return await Request.get("/company/index", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
  async getCompanyInfo(huobanid) {
    try {
      return await Request.get("", {huobanid});
    } catch(err) {
      return null;
    }
  },
  async getConcatorListByCompany(pageObj, companyHuobanId) {
    try {
      return await Request.get("/customer/list", {params: {huobanId: companyHuobanId, page: pageObj.currentPage, limit: pageObj.pageSize}});
    } catch(err) {
      return null;
    }
  },
  async modifyConcator(modifyInfo) {
    try {
      return await Request.put("/customer/edit", modifyInfo);
    } catch(err) {
      return null;
    }
  }
}