import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';
import { Config } from '../config';
import { UserApi } from '@/request';

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: () => import('../views/Main.vue'),
    redirect: '/Home',
    children: [
      {
        path: 'Refresh',
        name: 'RefreshView',
        component: () => import('../views/home/RefreshView.vue'),
        meta: {
          hidden: true,
          dontCtrlPremision: true //不控制权限
        }
      },
      {
        path: 'Home',
        name: 'HomeView',
        meta: {
          title: '首页',
          // showType: [Config.AdminMenuType],   //Admin 才显示
          dontCtrlPremision: true,
          icon: 'HomeFilled'
        },
        component: () => import('../views/home/HomeView.vue')
      },
      {
        path: 'Customer',
        name: 'CustomerView',
        meta: {
          title: '客户管理',
          showType: [Config.AdminMenuType, Config.ProductorMenuType],
          icon: 'Avatar'
        },
        children: [
          {
            path: 'CompanyList',
            name: 'CustomerView.CompanyList',
            component: () => import('../views/customer/CompanyListView.vue'),
            meta: {
              title: '公司列表',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          // {
          //   path: 'ConcatList',
          //   name: 'CustomerView.ConcatList',
          //   meta: {
          //     title: '联系人列表',
          //     showType: [Config.AdminMenuType, Config.ProductorMenuType],
          //     icon: 'List',
          //   }
          // }
        ]
      },
      {
        path: 'Goods',
        name: 'GoodsView',
        meta: {
          title: '产品管理',
          showType: [Config.AdminMenuType, Config.ProductorMenuType],
          icon: 'Grid'
        },
        children: [
          {
            path: 'JiajuList',
            name: 'GoodsView.JiajuList',
            component: () => import("../views/goods/JiajuListView.vue"),
            meta: {
              title: '家具产品',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'DengjuList',
            name: 'GoodsView.DengjuList',
            component: () => import("../views/goods/DengjuListView.vue"),
            meta: {
              title: '灯具产品',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'ChuanglianList',
            name: 'GoodsView.ChuanglianList',
            component: () => import("../views/goods/CurtainListView.vue"),
            meta: {
              title: '窗帘产品',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'GuahuaList',
            name: 'GoodsView.GuahuaList',
            component: () => import("../views/goods/PictureListView.vue"),
            meta: {
              title: '挂画产品',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'Series',
        name: 'SeriesView',
        meta: {
          title: '套系管理',
          showType: [Config.AdminMenuType, Config.ProductorMenuType],
          icon: 'Menu'
        },
        children: [
          {
            path: 'List',
            name: 'SeriesView.List',
            component: () => import("../views/series/ListView.vue"),
            meta: {
              title: '套系列表',
              showType: [Config.AdminMenuType, Config.ProductorMenuType],
              icon: 'List',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'Order',
        name: 'OrderView',
        meta: {
          title: '订单管理',
          showType: [Config.AdminMenuType],
          icon: 'Checked'
        },
        children: [
          {
            path: 'JiajuList',
            name: 'OrderView.JiajuList',
            component: () => import("../views/order/JiajuListView.vue"),
            meta: {
              title: '家具订单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'DengjuList',
            name: 'OrderView.DengjuList',
            component: () => import("../views/order/DengjuListView.vue"),
            meta: {
              title: '灯具订单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'ChuanglianList',
            name: 'OrderView.ChuanglianList',
            meta: {
              title: '窗帘订单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'GuahuaList',
            name: 'OrderView.GuahuaList',
            meta: {
              title: '挂画订单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'Saled',
        name: 'SaledView',
        meta: {
          title: '售后管理',
          showType: [Config.AdminMenuType],
          icon: 'Failed'
        },
        children: [
          {
            path: 'JiajuList',
            name: 'SaledView.JiajuList',
            component: () => import("../views/saled/JiajuListView.vue"),
            meta: {
              title: '家具售后单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'DengjuList',
            name: 'SaledView.DengjuList',
            component: () => import("../views/saled/DengjuListView.vue"),
            meta: {
              title: '灯具售后单列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'MinappConfig',
        name: 'MinappConfigView',
        meta: {
          title: '小程序管理',
          showType: [Config.AdminMenuType],
          icon: 'Platform'
        },
        children: [
          {
            path: 'IndexConfigList',
            name: 'MinappConfigView.IndexConfigList',
            component: () => import("../views/minapp/IndexConfigListView.vue"),
            meta: {
              title: '配置列表',
              showType: [Config.AdminMenuType],
              icon: 'House',
              keepAlive: true
            }
          },
          {
            path: 'IndexConfig',
            name: 'MinappConfigView.IndexConfig',
            component: () => import("../views/minapp/IndexConfigView.vue"),
            meta: {
              title: '首页配置',
              showType: [Config.AdminMenuType],
              icon: 'House',
              hidden: true,
              // keepAlive: true
            }
          },
          {
            path: 'DatabaseList',
            name: 'MiappConfigView.DatabaseList',
            component: () => import("../views/minapp/DatabaseListView.vue"),
            meta: {
              title: '资料库列表',
              showType: [Config.AdminMenuType],
              icon: 'Document',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'Administrator',
        name: 'AdministratorView',
        meta: {
          title: '系统管理员',
          showType: [Config.AdminMenuType],
          icon: 'UserFilled'
        },
        children: [
          {
            path: 'List',
            name: 'Administrator.List',
            component: () => import('../views/administrator/AdminListView.vue'),
            meta: {
              title: '管理员列表',
              showType: [Config.AdminMenuType],
              icon: 'List',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'Store',
        name: 'StoreView',
        meta: {
          title: '服务商管理',
          showType: [Config.AdminMenuType],
          icon: 'Shop'
        },
        children: [
          {
            path: 'StoreList',
            name: 'StoreView.StoreList',
            component: () => import("../views/store/StoreListView.vue"),
            meta: {
              title: '服务商列表',
              showType: [Config.AdminMenuType],
              icon: 'House',
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'CurtainSystem',
        name: 'CurtainSystemView',
        meta: {
          title: '窗帘计算系统',
          showType: [Config.AdminMenuType, Config.CurtainSystemType],
          icon: 'TrendCharts'
        },
        children: [
          {
            path: 'List',
            name: 'CurtainSystemView.List',
            // component: () => import(),
            meta: {
              title: '窗帘订单列表',
              showType: [Config.AdminMenuType, Config.CurtainSystemType],
              icon: 'List',
              keepAlive: true
            }
          },
          {
            path: 'Edit',
            name: 'CurtainSystemView.Edit',
            component: () => import('../views/curtain/EditAndView.vue'),
            meta: {
              title: '窗帘编辑和查看订单',
              showType: [Config.AdminMenuType, Config.CurtainSystemType],
              icon: 'CirclePlusFilled',
              hidden: true,
              keepAlive: true
            }
          }
        ]
      },
      {
        path: 'OrderToImage',
        name: 'OrderToImageView',
        meta: {
          title: '订单转图片',
          showType: [Config.AdminMenuType, Config.OrderToImageType],
          icon: 'PictureFilled'
        },
        children: [
          {
            path: 'JiajuOrder',
            name: 'OrderToImage.JiajuOrder',
            component: () => import("../views/order-to-image/JiajuToolView.vue"),
            meta: {
              title: '家具订单转图片',
              showType: [Config.AdminMenuType, Config.OrderToImageType],
              icon: 'OfficeBuilding',
              keepAlive: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/Login',
    name: 'LoginView',
    component: () => import('../views/home/LoginView.vue'),
    meta: {
      dontNeedPremision: true     //无需权限
    }
  },
  {
    path: '/ThreeDpannellum',
    name: 'ThreeDpannellum',
    component: () => import('../views/pannellum/index.vue'),
    meta: {
      dontNeedPremision: true 
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem(Config.TokenKey);
  // console.log(to, from);
  // console.log(store.state);
  if (to.meta.dontNeedPremision) {
    next();
  } else {
    if (!store.state.userInfo || !store.state.userInfo.mobile) {
      const userInfo = await UserApi.getUserInfo(); 
      if (!userInfo) {
        next({name: 'LoginView'});
        return;
      } else {
        store.commit('updateUserInfo', userInfo);
      }
    }
    if (to.meta.dontCtrlPremision && token) {
      // 不验证权限
      next();
    } else if ((to.meta.showType && to.meta.showType.indexOf(store.state.userInfo.type) >= 0) && token) {
      next();
    } else {
      // 无权限，阻止进入
      next({name: 'LoginView'})
    }
  }
})

export default router
