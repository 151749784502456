import Request from '../request';
import { getRightRequestUrl } from '@/assets/util/util';

export default {
  async getList(pageObj, searchObj) {
    try {
      return await Request.get("/store/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
  async save(data) {
    try {
      return await Request.post("/store/save", data);
    } catch(err) {
      return null;
    }
  },
  async modifyStatus(statusObj) {
    try {
      return await Request.put("/store/status", statusObj);
    } catch(err) {
      return null;
    }
  },
  async deleteById(id) {
    try {
      return await Request.delete(`/store/delete/${id}`);
    } catch(err) {
      return null;
    }
  }
}