import Request from '../request';

export default {
  async getList(pageObj, searchObj) {
    try {
      return await Request.get("/goods/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
  async editGoods(data) {
    try {
      return await Request.put("/goods/edit", data);
    } catch(err) {
      return null;
    }
  },
  async keyValueGoods(data) {
    try {
      return await Request.put("/goods/edit/kv", data);
    } catch(err) {
      return null;
    }
  }
}