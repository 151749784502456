import Request from '../request';

export default {
  async getList(pageObj, searchObj) {
    try {
      return await Request.get("/series/list", {params: Object.assign({page: pageObj.currentPage, limit: pageObj.pageSize}, searchObj)});
    } catch(err) {
      return null;
    }
  },
  async editSeries(data) {
    try {
      return await Request.put("/series/edit", data);
    } catch(err) {
      return null;
    }
  },
  async modifyStatus(statusObj) {
    try {
      return await Request.put("/series/status", statusObj);
    } catch(err) {
      return null;
    }
  }
}