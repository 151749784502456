import { Config } from "@/config";

export function setPageSize(page, pageSize) {
  // page.value.pageSize = pageSize - 0;
  localStorage.setItem(Config.PageSizeKey, pageSize);
}

export function getPageSize() {
  return (localStorage.getItem(Config.PageSizeKey) || Config.PageSizeNumber) - 0;
}

export function getPageSizeArray() {
  return Config.PageSizeArray;
}

export function getAuthToken() {
  return localStorage.getItem(Config.TokenKey) || '';
}

export function getRightRequestUrl(type = 'admin', system = 'minapp') {
  const baseUrl = getRightMinappBaseUrl();
  const baseApi = getRightMinappBaseApi();
  let pre = Config.MinappAxiosBasePreAdmin;
  if (system === 'minapp') {
    switch(type) {
      case 'admin': 
        pre = Config.MinappAxiosBasePreAdmin;
        break;
      case 'public':
        pre = Config.MinappAxiosBasePrePublic;
        break;
      default: 
        break;
    }
  }
  return baseUrl + baseApi + pre;
}

export function getRightMinappBaseUrl() {
  return Config.Env == 'Dev' ? Config.MinappAxiosBaseURLDev : Config.MinappAxiosBaseURL;
}

export function getRightMinappBaseApi() {
  return Config.Env == 'Dev' ? Config.MinappAxiosBaseApiDev : Config.MinappAxiosBaseApi;
}