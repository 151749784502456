import Axios from 'axios';
import {
  ElMessage 
} from 'element-plus';
import router from "../router";
import { Config } from '../config';
import { getRightRequestUrl } from '../assets/util/util.js';

const axios = Axios.create({
  baseURL: getRightRequestUrl(),
  timeout: Config.AxiosTimeout,
  withCredentials: true
});

// 请求拦截器
axios.interceptors.request.use(config => {
  //带上登录信息
  const token = localStorage.getItem(Config.TokenKey);
  config.headers.Authorization = `${token}`;
  return config;
}, err => {
  console.log("interceptors.request error: ", err);
  ElMessage.error(err);
  // return Promise.reject(err);
  return null;
});

//获取数据拦截
axios.interceptors.response.use(response => {
  // 判断 code 
  const { data } = response;
  if (data.code == 200) {
    return data.data;
  } 
  if (data.code == 301) {
    // token过期，重新登录
    localStorage.setItem(Config.TokenKey, '');
    router.push({
      name: 'LoginView'
    });
  } else if (data.code == 400) {
    ElMessage.warning(data.msg);
  }
  // return Promise.reject(data.msg);
  return null;
}, err => {
  console.log("interceptors.response error: ", err);
  ElMessage.error(err);
  // return Promise.reject(err);
  return null;
});

export default axios;