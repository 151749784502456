import Request from '../request';

export default {
  async getOrderInfo(type, orderId) {
    try {
      return await Request.get("/current/order", {params: {type, order_id: orderId}});
    } catch(err) {
      return null;
    }
  },
}